import { BannerType } from '../components/banner';
import { FAQData } from '../components/faq/types';
import { NewsletterProps } from '../components/newsletter/types';
import { TestimonialProps } from '../components/testimonials/types';
import { IconCardProps } from '../components/types';
import { WhyUsCardProps } from '../components/why-is-important';

export const banner: BannerType = {
  heading: 'cohabitationAgreementLanding.mainBanner.title',
  subHeading: 'cohabitationAgreementLanding.mainBanner.subtitle',
  btnLabel: 'cohabitationAgreementLanding.mainBanner.buttonLabel',
  url: '/registrieren',
  youtubePlayer: {
    url: {
      en: 'https://www.youtube.com/watch?v=rzWac73QE4s',
      de: 'https://youtu.be/9Q9PVtzEBjc',
      fr: 'https://www.youtube.com/watch?v=8eyxqiMppeE',
      it: 'https://www.youtube.com/watch?v=Kxatr137lUY',
    },
  },
  ytThumbnail: 'y_konkubinatsvertrag.webp',
};

export const whyIsImportant: WhyUsCardProps[] = [
  {
    title: 'cohabitationAgreementLanding.aboutCards.proactiveMeasures.title',
    description:
      'cohabitationAgreementLanding.aboutCards.proactiveMeasures.description',
    icon: '/assets/icons/proactiveMeasure.svg',
  },
  {
    title: 'cohabitationAgreementLanding.aboutCards.defineWishes.title',
    description:
      'cohabitationAgreementLanding.aboutCards.defineWishes.description',
    icon: '/assets/icons/wishesYouCanAdapt.svg',
  },
  {
    title: 'cohabitationAgreementLanding.aboutCards.benefitMaximally.title',
    description:
      'cohabitationAgreementLanding.aboutCards.benefitMaximally.description',
    icon: '/assets/icons/inexpensive.svg',
  },
];

export const eightReasons: IconCardProps[] = [
  {
    title: 'provisionLanding.eightReasons.cards.card1.title',
    description:
      'provisionLanding.eightReasons.cards.card1.description.konkubinatsvertrag',
    icon: '/assets/icons/whyUs1.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card2.title',
    description:
      'provisionLanding.eightReasons.cards.card2.description.konkubinatsvertrag',
    icon: '/assets/icons/whyUs2.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card3.title',
    description:
      'provisionLanding.eightReasons.cards.card3.description.konkubinatsvertrag',
    icon: '/assets/icons/conveniently.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card4.title',
    description:
      'provisionLanding.eightReasons.cards.card4.description.konkubinatsvertrag',
    icon: '/assets/icons/costumisable.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card5.title',
    description:
      'provisionLanding.eightReasons.cards.card5.description.konkubinatsvertrag',
    icon: '/assets/icons/enforceWishes.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card6.title',
    description:
      'provisionLanding.eightReasons.cards.card6.description.konkubinatsvertrag',
    icon: '/assets/icons/mutualProtection.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card7.title',
    description:
      'provisionLanding.eightReasons.cards.card7.description.konkubinatsvertrag',
    icon: '/assets/icons/access.svg',
  },
  {
    title: 'provisionLanding.eightReasons.cards.card8.title',
    description:
      'provisionLanding.eightReasons.cards.card8.description.konkubinatsvertrag',
    icon: '/assets/icons/whyUs3.svg',
  },
];

export const testimonials: TestimonialProps[] = [
  {
    name: 'provisionLanding.testimonials.testimonial1.name',
    testimonial: 'provisionLanding.testimonials.testimonial1.text',
  },
  {
    name: 'provisionLanding.testimonials.testimonial2.name',
    testimonial: 'provisionLanding.testimonials.testimonial2.text',
  },
  {
    name: 'provisionLanding.testimonials.testimonial3.name',
    testimonial: 'provisionLanding.testimonials.testimonial3.text',
  },
];

export const secondBanner: BannerType = {
  heading: 'cohabitationAgreementLanding.subBanner.title',
  subHeading: 'cohabitationAgreementLanding.subBanner.subtitle',
  url: '/registrieren',
  btnLabel: 'cohabitationAgreementLanding.subBanner.buttonLabel',
  image:
    'https://storage.gutgeregelt.ch/assets/main-landings/banners/cohabitationAgreementBanner.webp',
};

export const faqData: FAQData = {
  questions: [
    {
      question: 'cohabitationAgreementLanding.faq.questions.question1',
      answer: 'cohabitationAgreementLanding.faq.answers.answer1',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question2',
      answer: 'cohabitationAgreementLanding.faq.answers.answer2',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question3',
      answer: 'cohabitationAgreementLanding.faq.answers.answer3',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question4',
      answer: 'cohabitationAgreementLanding.faq.answers.answer4',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question5',
      answer: 'cohabitationAgreementLanding.faq.answers.answer5',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question6',
      answer: 'cohabitationAgreementLanding.faq.answers.answer6',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question7',
      answer: 'cohabitationAgreementLanding.faq.answers.answer7',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question8',
      answer: 'cohabitationAgreementLanding.faq.answers.answer8',
    },
    {
      question: 'cohabitationAgreementLanding.faq.questions.question9',
      answer: 'cohabitationAgreementLanding.faq.answers.answer9',
    },
  ],
};

export const newsletter: NewsletterProps = {
  title: 'cohabitationAgreementLanding.newsletter.title',
  buttonLabel: 'cohabitationAgreementLanding.newsletter.buttonLabel',
  url: '/registrieren',
};

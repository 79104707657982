import { Box, Button, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Banner } from '../components/banner';
import useLocales from '#/lib/hooks/useLocales';
import WhyIsImportant from '../components/why-is-important';
import {
  banner,
  eightReasons,
  faqData,
  newsletter,
  secondBanner,
  testimonials,
  whyIsImportant,
} from './constants';
import EightReasons from '../components/8-reasons/eight-reasons';
import ContentContainer from '../components/content-container';
import Testimonials from '../components/testimonials/testimonials';
import FAQ from '../components/faq/faq';
import Newsletter from '../components/newsletter/newsletter';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import Page from '#/components/shared/ui/Page';

const CohabitationAgreement = () => {
  const [, setReqService] = useLocalStorage('req-service', null);

  const { translate, currentLang } = useLocales();

  const handleReqService = (service: string) => {
    setReqService(service);
  };

  const pageTitle = translate('metadata.cohabitation_agreement.title');
  const pageDescription = translate(
    'metadata.cohabitation_agreement.description'
  );

  return (
    <Page title={pageTitle} description={pageDescription}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: {
            xs: 4,
            md: 10,
          },
        }}
      >
        <ContentContainer>
          <Banner
            ytThumbnail={banner.ytThumbnail}
            intro={banner.intro}
            heading={banner.heading}
            subHeading={banner.subHeading}
            youtubeSrc={banner.youtubePlayer?.url[currentLang.value]}
            cta={
              <Box>
                <Link
                  component={RouterLink}
                  to={banner.url}
                  state={{
                    redirectLink: `/dashboard/konkubinatsvertrag`,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleReqService('konkubinatsvertrag')}
                  >
                    <Typography>{translate(banner.btnLabel)}</Typography>
                  </Button>
                </Link>
              </Box>
            }
          />
        </ContentContainer>
        <ContentContainer>
          <WhyIsImportant
            title="cohabitationAgreementLanding.youtubeTutorial"
            cards={whyIsImportant}
          />
        </ContentContainer>
        <ContentContainer>
          <EightReasons cards={eightReasons} />
        </ContentContainer>
        <Testimonials testimonials={testimonials} />
        <ContentContainer>
          <Banner
            imgPosition="left"
            showTrustLaurel={false}
            heading={secondBanner.heading}
            subHeading={secondBanner.subHeading}
            image={secondBanner.image}
            cta={
              <Box>
                <Link
                  component={RouterLink}
                  to={secondBanner.url}
                  state={{
                    redirectLink: `/dashboard/konkubinatsvertrag`,
                  }}
                >
                  <Button variant="outlined">
                    <Typography>{translate(secondBanner.btnLabel)}</Typography>
                  </Button>
                </Link>
              </Box>
            }
          />
        </ContentContainer>
        <FAQ faqList={faqData.questions} />
        <Newsletter newsletter={newsletter} service="konkubinatsvertrag" />
      </Box>
    </Page>
  );
};

export default CohabitationAgreement;
